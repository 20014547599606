<template>
  <div class="require-stock-to-nhanh">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview
      v-bind:title="'Danh sách phiếu yêu cầu xuất chuyển kho sang hệ thống cũ'"
    >
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
              <span class="mr-1 ml-1"></span>
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="sourceWarehouseName"
              :suggestions="filteredSourceStoreOptions"
              placeholder="kho nguồn"
              :limit="10"
              @select="onSelected"
              @change="onInputChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="destinationWarehouseName"
              :suggestions="filteredDStockOptions"
              placeholder="kho đích"
              :limit="10"
              @select="onDestinationSelected"
              @change="onSearchDestinationChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              required
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="search"
              type="text"
              placeholder="Tìm kiếm"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="stockSlips"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(countProduct)="row">
            <div style="text-align: right">
              <span v-text="row.item.countProduct" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(countQuantity)="row">
            <div style="text-align: right">
              <span v-text="row.item.countQuantity" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(code)="row">
            <div
              style="
                justify-content: center;
                align-items: center;
                display: grid;
              "
            >
              <span v-text="row.item.code"></span>
            </div>
          </template>
          <template v-slot:cell(relateStockCode)="row">
            <div
              class="productCode"
              @click="viewTransferStock(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.relateStockCode"></span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createBy }}</p>
          </template>

          <template v-slot:cell(approve)="row">
            <div
              v-if="row.item.status === 1"
              style="cursor: pointer"
              @click="showApproveAlert(row.item.id)"
              class="d-flex justify-content-center align-items-center"
            >
              <v-icon
                small
                style="font-size: 13px"
                v-b-tooltip
                title="Duyệt phiếu"
                v-if="checkPermission('STOCK_UPDATE')"
                >menu-icon flaticon2-checking</v-icon
              >
            </div>
            <div v-else>
              <p class="text-center text-primary mb-0">Đã duyệt</p>
              <p class="text-center">{{ row.item.approveBy }}</p>
              <p class="text-center text-primary mb-0">
                {{ row.item.approveDate }}
              </p>
            </div>
          </template>

          <template v-slot:cell(sourceWarehouseId)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.sourceWarehouseName"></span>
              <br />
              <i
                style="font-size: 1rem; color: blue"
                class="flaticon2-right-arrow"
              ></i>
              <br />
              <span v-text="row.item.destinationWarehouseName"></span>
            </div>
          </template>
          <template v-slot:cell(description)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
            >
              <span v-text="row.item.description" class="mr-2"></span>
              <span @click="handleShowNoteModel(row.item)">
                <i v-if="hoverPrice" class="fas fa-edit"></i>
              </span>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchStock"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú</label
                >
                <b-form-textarea
                  id="textarea"
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import { STOCK_SLIP_TYPE } from '@/utils/enum';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stockSlips: [],
      search: '',
      sourceWarehouseName: '',
      searchId: '',
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: STOCK_SLIP_TYPE.REQUIRE_EXPORT_STOCK_TO_NHANH,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'relateStockCode',
          label: 'Từ phiếu CK',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'countProduct',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'countQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'approve',
          label: 'Duyệt',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      sourceWarehouseId: null,
      optionStores: [],
      filteredSourceStoreOptions: [],
      limit: 10,
      destinationWarehouseName: '',
      destinationWarehouseId: '',
      filteredDStockOptions: [],
      searchProduct: '',
      selectedStatus: 0,
      listStatus: [
        {
          id: 0,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Chưa duyệt',
        },
        {
          id: 2,
          name: 'Đã duyệt',
        },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
    };
  },
  methods: {
    ...getToastInstance(),
    fetchStore: async function () {
      this.optionStores = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionStores = stores.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredSourceStoreOptions = [...this.optionStores];
        this.filteredDStockOptions = [...this.optionStores];
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        searchId: this.searchId,
        status: this.selectedStatus,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        searchStock: this.sourceWarehouseName,
        type: this.type,
        searchDestinationStock: this.destinationWarehouseName,
        searchProduct: this.searchProduct,
      };

      ApiService.query('requireStocks/getAll', { params })
        .then((response) => {
          this.stockSlips = [];
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          this.stockSlips = stockResponse.map((item, index) => {
            return {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              sourceWarehouseName: item.sourceWarehouseName,
              destinationWarehouseId: item.destinationWarehouseId,
              destinationWarehouseName: item.destinationWarehouseName,
              description: item.description,
              status: item.status,
              totalAmount: item.totalAmount,
              createBy: item.createBy,
              updateBy: item.updateBy,
              createdAt: moment(String(item.createdAt)).format('DD/MM/YYYY'),
              approveBy: item.approveBy,
              approveDate: moment(String(item.approveDate)).format(
                'DD/MM/YYYY HH:mm:ss'
              ),
              relateStock: item.relateStockSlipId,
              relateStockCode: item.relateStockCode,
              countProduct: item.countProduct,
              countQuantity: item.countQuantity,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    onSelected(option) {
      this.sourceWarehouseId = option.item.id;
      this.sourceWarehouseName = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.sourceWarehouseName = text;

      const filteredData = this.optionStores
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredSourceStoreOptions = [...filteredData];
    },

    showApproveAlert: function (id) {
      this.$router.push({
        name: 'approve-transfer-stock-to-nhanh',
        query: { id: id },
      });
    },
    viewTransferStock: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-transfer-stock-to-nhanh',
        query: { id: item.relateStock },
      });
      window.open(href, '_blank');
    },
    onDestinationSelected(option) {
      this.destinationWarehouseId = option.item.id;
      this.destinationWarehouseName = option.item.name;
    },
    onSearchDestinationChange(text) {
      if (!text) {
        text = '';
      }
      this.destinationWarehouseName = text;

      const filteredData = this.optionStores
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredDStockOptions = [...filteredData];
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-require-stock-to-nhanh',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      let data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };
      ApiService.setHeader();
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Phiếu yêu cầu xuất chuyển kho sang hệ thống cũ',
        route: 'require-stock-to-nhanh',
      },
      { title: 'Danh sách phiếu yêu cầu xuất chuyển kho sang hệ thống cũ' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchStock();
    this.fetchStore();
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
  },
};
</script>

<style lang="scss">
.require-stock-to-nhanh {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }
  .productCode:hover {
    text-decoration: underline;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
